    @tailwind base;
    @tailwind components;
    @tailwind utilities;
    @font-face {
        font-family: 'Epilogue';
        src: url('../assets/fonts/Epilogue-Regular.ttf');
    }
    
    @font-face {
        font-family: 'Epilogue Semibold';
        src: url('../assets/fonts/Epilogue-SemiBold.ttf');
    }
    
    @font-face {
        font-family: 'Epilogue Bold';
        src: url('../assets/fonts/Epilogue-Bold.ttf');
    }
    
    @font-face {
        font-family: 'WorkSans';
        src: url('../assets/fonts/WorkSans-Regular.ttf');
    }
    
    @font-face {
        font-family: 'WorkSans Semibold';
        src: url('../assets/fonts/WorkSans-SemiBold.ttf');
    }
    
    @font-face {
        font-family: 'WorkSans Bold';
        src: url('../assets/fonts/Epilogue-Bold.ttf');
    }
    /*Home css */
    
    .app-nav {
        width: 150px;
        position: absolute;
        background: #fff;
        transform: translateY(0px);
        right: 1.5rem;
    }
    
    @media (min-width: 768px) {
        .testimonials-wrapper {
            /* background: url('../assets/images/testimonals_bg.svg');
            background-size: 90%;
            background-position: 50% 50%;
            background-repeat: no-repeat; */
            /* min-height: 480px; */
        }
    }
    /* @media (min-width: 1200px) {
        .testimonal-container-data {
            max-width: 1200px;
            margin: auto;
        }
    } */
    
    html {
        scroll-behavior: smooth;
    }